.items{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.items fieldset{
    width: 15%;
}
#flexGrap{
    display: flex;
    gap: 1rem;
    width: 100%;
     
}
#flexGrap fieldset{
    width: 100%!important;
}
#flexDe{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
}

@media (max-width: 824px){
    .items fieldset{
        width: 45%!important;
    }
    .items{
        display: flex;
        gap: 10px;
        justify-content: space-between;
       
      
    }

    #flexGrap{
      flex-direction: column;
         
    }
    #flexGrap fieldset{
        width: 100%!important;
    }
    #flexDe{
        flex-direction: column;
    }
}